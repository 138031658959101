.QuestionsContainer {
    width: 100vw;
    margin-bottom: 50px;
    max-width: 1600px;
    display: flex;
    justify-content: center;
}

.QuestionsSubContainerLeft {
    width: 50%;
    height: 100%;
    display: flex;
    padding-right: 50px;
    align-items: center;
    justify-content: end;
}

.QuestionsSubContainerRight {
    width: 50%;
    height: 100%;
    display: flex;
    padding-left: 50px;
    align-items: center;
    justify-content: start;
}

.QuestionsTextContainer {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    
}

.QuestionsImage {
    width: 65%;
    max-width: 600px;
    min-width: 400px;
    background-color: #FCFCFC;
    object-fit: contain;
    border-radius: 5px;
    padding: 20px;
}

.QuestionsIntroductionText {
    font-size: 18px;
    margin: 0;
    padding: 0;
    color: #5E5E5E;
}
.QuestionsHeaderText {
    font-size: 38px;
    margin-top:10px;
}

.QuestionsDescriptionText {
    font-size: 20px;
    margin-top: 0px;
    color: #5E5E5E;
}

@media screen and (max-width: 768px) {
    .QuestionsContainer {
        flex-direction: column; /* Stack the containers vertically */
        justify-content: center;
        align-items: center;
    }

    .QuestionsTextContainer {
        width: 100%;
    }

    .QuestionsHeaderText {
        margin-bottom: 25px;
    }

    .QuestionsImage {
        width: 100%;
        min-width: 270px;
    }

    .QuestionsSubContainerLeft {
        justify-content: center;
        padding-right: 0;
        width: 70%;
    }

    .QuestionsSubContainerRight {
        justify-content: center;
        padding-left: 0;
        width: 80%;
    }
}
