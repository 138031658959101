.HeroSectionContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 92vh;
    width: 100%;
    position: relative;
}



.HeroSectionContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 92vh;
    width: 100%;
    z-index: 20;
    position: relative;
}

.HeroSectionTextButtonContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 30%;
   
}

.HeroSectionLogoContainer{
    position: absolute;
    top: 20px;
    left: 30px;
    color: black;
    font-size: 50px;
    font-weight: bold;
 
}

.HeroSectionText{
    font-size: 20px;
}


.HeroSectionTextTitle {
    margin: 5px;
    font-weight: bold;
    color: black;
    font-size: 50px;

}


.HeroSectionButtonContainer{
    
    align-items: center;
    justify-content: center;
    flex: 1;
    margin-top: 20px;
}

@media screen and (max-width: 820px) {
    .HeroSectionTextContainer{
        font-size: 40px;
    }
}


@media screen and (max-width: 820px) {
    .HeroSectionTextTitle {
        font-size: 40px;
        margin: 3px; /* Minskad marginal för mellanstora skärmar */
    }
}

@media screen and (max-width: 620px) {
    .HeroSectionTextTitle {
        font-size: 35px;
        margin: 2px; /* Ännu mindre marginal för små mobilskärmar */
    }

    .HeroSectionTextButtonContainer {
        width: 60%;
    }
    .HeroSectionContainer {
        height: 70vh;
    }
}

