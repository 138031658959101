

.FeatureContainer {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center horizontally */
}

.FeatureTextContainer {
    display: flex;
    flex-direction: column;
    align-items: center;  /* Center horizontally */
    justify-content: center; /* Center vertically */
    margin: 20px 0; /* Optional margin */
}

.FeatureTextTitle {
    font-size: 50px;
    font-weight: bold;
    margin: 0;
}

.FeatureTextSubtitle { /* Corrected to match JSX spelling */
    font-size: 20px;
    margin-top: 10px; /* Adjusted for better spacing */
    color: #000000;
}

.FeatureBoxesContainer {
    width: 100%; /* Reduced width */
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin: 0 auto; /* Centers the container horizontally */
}


.UpperBoxes, .LowerBoxes {
    display: flex;
    flex-direction: row; /* Set them side by side */
    gap: 20px; /* Add spacing between boxes */
    width: 80%;
    justify-content: space-around;
}

.UpperLeftBox, .UpperRightBox, .LowerLeftBox, .LowerMiddleBox, .LowerRightBox {
    background-color: #f4f4f4; /* Example box background */
    margin-top: 20px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Add shadow for effect */
    flex: 1; /* Allow boxes to take equal width */
    min-width: 250px; /* Ensure minimum width for responsiveness */
}

.LowerLeftBox, .LowerMiddleBox, .LowerRightBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.UpperBoxTitle {
    font-size: 35px;
    margin-left: 10px;
    text-align: left;
    font-weight: bold;
    margin-bottom: 10px;
}
.LowerBoxTitle {
    font-size: 23px;
    margin-left: 10px;
    text-align: left;
    font-weight: bold;
    margin-bottom: 10px;
}

.UpperBoxText {
    font-size: 20px;
    margin-left: 10px;
    text-align: left;
}
.LowerBoxText {
    font-size: 18px;
    margin-left: 10px;
    text-align: left;
}

.GifImage {
    width: 65%;
    object-fit: cover;
    border-radius: 10px;
}

.BottomImg {
    width: 60%;
    object-fit: cover;
    border-radius: 10px;
}



/*When screen is smaller*/

/* Textjustering för små skärmar */
@media screen and (max-width: 1000px) {
    .FeatureContainer {
        margin-top: -70px; /* Lägg till lite padding */
    }
    .FeatureTextTitle {
        font-size: 30px; /* Minska titelstorleken */
    }

    .FeatureTextSubtitle {
        font-size: 16px; /* Minska undertitelstorleken */
        width: 80%; /* Gör texten smalare */
    }

    .UpperBoxes, .LowerBoxes {
        flex-direction: column; /* Stapla rutorna */
        gap: 20px; /* Ge avstånd mellan rutorna */
        width: 90%; /* Låt dem inte ta upp hela bredden */
        margin: 0 auto; /* Centrera rutorna */
    }

    .UpperLeftBox, .UpperRightBox, .LowerLeftBox, .LowerMiddleBox, .LowerRightBox {
        max-width: 350px; /* Begränsa bredden på rutorna */
        width: 100%;
    }

    .UpperBoxTitle, .LowerBoxTitle {
        font-size: 20px; /* Justera titlarnas storlek */
    }

    .UpperBoxText, .LowerBoxText {
        font-size: 14px; /* Gör texten mindre */
    }

    .GifImage, .BottomImg {
        width: 80%; /* Anpassa bildstorlek */
    }
}

/* Extra justeringar för ännu mindre skärmar */
@media screen and (max-width: 600px) {
    .FeatureTextTitle {
        font-size: 30px;
    }

    .FeatureTextSubtitle {
        font-size: 14px;
    }

    .UpperBoxTitle, .LowerBoxTitle {
        font-size: 18px;
    }

    .UpperBoxText, .LowerBoxText {
        font-size: 14px;
    }
}
