.SafetySegmentContainer {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 5%;
}


.SafetyIntroductionText {
    font-size: 20px;
    font-weight: 400;
    margin-top: -30px;
    color: #000000;
}

.SafetyHeaderText {
    margin-top: 0;
    font-size: 40px;
    font-weight: bold;
    color: #000000;
   
}

.SafetyContainer {
    margin-top: 20px;
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap; /* This allows items to wrap when necessary */
}

@media (max-width: 620px) {
    .SafetyContainer {
        flex-direction: column; /* Ändra till kolumnformat */
        align-items: center;
    }

    .SafetyHeaderText {
        max-width: 90vw;
        font-size: 30px; /* Minska fontstorleken på mobil */
    }

    .SafetyIntroductionText {
        font-size: 16px; /* Minska fontstorleken på mobil */
    }
}
