.SearchDemoContainer {
    display: flex;
    flex-direction: column;
    width: 100vw;
    align-items: center;
    padding-bottom: 100px;

}

.SearchDemoIntroduction {
    font-size: 20px;
    margin-bottom: 1rem;
    margin-top: 100px;
}

.SearchDemoText {
    margin-top: 0;
    font-size: 38px;
    font-weight: bold;
    max-width: 80vw;
}

.SmartSearchDemoVideo {
    width: 85%;
    border-radius: 15px;
    min-width: 300px;
    max-width: 1150px;
    object-fit: contain;
    box-shadow: 1px 8px 20px 0px rgb(0 0 0 / 30%);
}

@media screen and (max-width: 768px) {

    .SearchDemoText {
        font-size: 30px;
        max-width: 90vw;
    }
    
}