.HeaderContainer {
    position: relative;
    width: 100%;
    height: 60px;
}

.HeaderLogo {
    position: absolute;
    display: flex;
    left: 10px;
    top: 10px;
    align-items: center;
    height: 50px;
}

.HeaderLogoImage {
    height: 40px;
    margin: 5px;
}

.NavBar {
    height: 100%;
    padding-top: 10px;
    display: flex;
    justify-content: center; /* Centrerar länkarna */
    align-items: flex-start; /* Centrerar länkarna */
    flex: 1; /* Tar upp mittutrymme */
    text-align: center; /* Centrerar länkarna */
}

.NavBar a {
    margin: 0 10px; /* Lite mellanrum mellan länkarna */
    text-decoration: none; /* Tar bort understrykning */
    color: black;
}

/* Lägg till media queries för mobil */
@media (max-width: 768px) {
    .NavBar {
        display: none; /* Dölj nav-baren på mindre skärmar */
    }
}
