.FeatureShowcaseContainer {
    background-color: #F9F9F9;
    width: 240px;
    text-align: center;
    padding: 30px;
    border-radius: 15px;
    margin: 10px;
}

.FeatureShowcaseContainer:hover {
    width: 260px;
    padding: 40px;
    margin: 0px;
}

.FeatureShowcaseTitle {
    font-size: 18px;
    font-weight: 600;
    margin-top: 0px;
}

.FeatureShowcaseText {
    font-size: 14px;
    font-weight: 500;
    color:black;
    margin-top: -10px;
}