.ChatContainer {
    width: 100vw;
    margin-bottom: 150px;
    max-width: 1600px;
    display: flex;
    justify-content: center;
    margin-top: 140px;
}

.ChatSubContainerLeft {
    width: 50%;
    height: 100%;
    display: flex;
    padding-right: 50px;
    align-items: center;
    justify-content: end;
}

.ChatSubContainerRight {
    width: 50%;
    height: 100%;
    display: flex;
    padding-left: 50px;
    align-items: center;
    justify-content: start;
}

.ChatTextContainer {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
}

.ChatImage {
    width: 65%;
    max-width: 600px;
    min-width: 400px;
    background-color: #FCFCFC;
    object-fit: contain;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 30px;
}

.ChatIntroductionText {
    font-size: 18px;
    margin: 0;
    padding: 0;
    color: #000000;
}

.ChatHeaderText {
    font-size: 38px;
    font-weight: bold;
    margin-top: 0px;
}

.ChatDescriptionText {
    font-size: 20px;
    margin-top: -30px;
    color: #000000;
}

/* Responsiv design för skärmar mindre än 768px */
/* Responsiv design för skärmar mindre än 768px */
/* Responsiv design för skärmar mindre än 768px */
@media screen and (max-width: 768px) {
    .ChatContainer {
        flex-direction: column; /* Lägg innehållet vertikalt */
        justify-content: center;
        align-items: center;
    }

    .ChatSubContainerLeft,
    .ChatSubContainerRight {
        width: 100%; /* Full bredd */
        display: flex;
        justify-content: center; 
        padding: 0; /* Ta bort padding */
        flex-direction: column; /* Viktigt för vertikal placering av innehållet */
    }

    .ChatTextContainer {
        width: 80%; /* Justera bredden */
        order: 1; /* Placera texten över bilden */
       
        margin-bottom: 20px; /* Lägg till lite mellanrum mellan texten och bilden */
    }

    .ChatImage {
        width: 90%; /* Justera bildens storlek */
        min-width: 270px;
        order: 2; /* Flytta bilden under texten */
        background-color: #FCFCFC;
        object-fit: contain;
        border-radius: 5px;
        padding: 20px;
        margin-top: 20px; /* Lägg till mellanrum ovanför bilden */
    }

    .ChatIntroductionText {
        font-size: 16px; /* Mindre textstorlek */
    }

    .ChatHeaderText {
        font-size: 28px; /* Minska rubrikstorleken */
        margin-bottom: 15px;
    }

    .ChatDescriptionText {
        font-size: 16px; /* Justera textstorleken */
        margin-top: 10px;
    }
}

