

.StandardButton {
  background-color: #0075FF;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: white;
  font-size: 24px;
  border: none;
  font-weight: 700;
  padding: 1px 30px;
  line-height: 50px; 
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}



.StandardButton:hover {
  background-color: #0045FF;
  
  
}

.StandardButtonIcon {
  margin-left: 10px;
  border: 1px solid #AEAEAE;
  border-radius: 4px;
}


@media only screen and (max-width: 600px) {
  .StandardButton {
    font-size: 16px;
    padding: 3px 10px;
    line-height: 40px;
  }
}
