

.GPTAnswerContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    text-align: left;
    margin-left: 10px;
    font-size: 20px;
    font-weight: 500;
    color: black;
}

.GPTAnswerContainer p {
    margin: 0;
}

.GPTAnswerQuestion {
    margin-bottom: 0px;
    font-size: 20px;
}

.GPTAnswerAnswerContainer{
    position: relative;
}

.GPTAnswerAnswer {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}

.GPTAnswerAnswerFiller {
    color: rgba(255, 255, 255, 0);
}

@media screen and (max-width: 1000px) {
    .GPTAnswerContainer {
        font-size: 14px;
    }
}
