.FooterRoot {
    display: flex;
    width: 100vw;
    background-color: #1D1D1D;
    justify-content: center;
}

.FooterContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 300px;
    width: 100vw;
    max-width: 1500px;
    position: relative;
    padding: 0 15px;  /* Lägg till lite padding för mindre skärmar */
}

.FooterSubContainer, .FooterSubContainerLeft {
    width: 30%;
    max-width: 400px;
    height: 100%;
    padding-top: 60px;
    padding-bottom: 60px;
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: space-between;
    position: relative;
}

.FooterSubContainerLeft {
    align-items: flex-end;
}

.FooterRightButtonAndText {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.FooterIntroduction {
    font-size: 17px;
    margin-top: 0px;
    color: white;
}

.FooterText {
    color: white;
    margin: 0px;
    font-weight: bold;
    font-size: 30px;
}

.FooterPromoText {
    margin-top: 0;
    color: white;
}

.FooterButtonContainer {
    margin-top: 0px;
}

.FooterSecondaryText {
    color: #cbcbcb;
    font-size: 13px;
}



@media screen and (max-width: 800px) {
    .FooterContainer{
        height: 300px;
    }
    .FooterSubContainer{
        width:65%;

    }
    .FooterRightButtonAndText {
        flex-direction: column; /* Stapla elementen vertikalt */
        align-items: center;   /* Centrera elementen */
        text-align: center;    /* Justera texten till mitten */
    }

    .FooterButtonContainer {
        margin-top: 40px; /* Lägg till lite mellanrum mellan text och knapp */
        width: 100%;      /* Se till att knappen fyller ut bredden */
    }

    .FooterPromoText {
        display:none; /* Lägg till mellanrum under texten */
        font-size: 25px;
    }
    .FooterIntroduction{
        font-size: 15px;
    }
    .FooterText {
        font-size: 25px;
    }
}


