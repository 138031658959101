.AnswerGenerationContainer {
    width: 100vw;
    min-height: 50vh;
    padding-top: 160px;
    padding-bottom: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.AnswerGenerationImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;

}

.AnswerGenerationIntroduction {
    font-size: 20px;
    font-weight: 300;
    color: white;
}

.AnswerGenerationHeader {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 36px;
    font-weight: bold;
    color: white;
}

.AnswerGenerationTextContainer {
    width: 60%;
    display: flex;
    flex-direction: column;
    text-align: left;
}

@media screen and (max-width: 600px) {
    .AnswerGenerationIntroduction {
        font-size: 18px;
    }

    .AnswerGenerationHeader {
        font-size: 28px;
    }

    .AnswerGenerationTextContainer {
        width: 80%;
    }
    
}
