.BidPlatformContainer {
    width: 100vw;
    margin-bottom: 150px;
    max-width: 1600px;
    display: flex;
    justify-content: center;
}

.BidPlatformSubContainerLeft {
    width: 50%;
    height: 100%;
    display: flex;
    padding-right: 50px;
    align-items: center;
    justify-content: end;
}

.BidPlatformSubContainerRight {
    width: 50%;
    height: 100%;
    display: flex;
    padding-left: 50px;
    align-items: center;
    justify-content: start;
}

.BidPlatformTextContainer {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    
}

.BidPlatformVideo {
    width: 65%;
    max-width: 600px;
    min-width: 400px;
    height: 350px;
    background-color: #FCFCFC;
    object-fit: contain;
    border-radius: 5px;
    padding: 20px;
}

.BidPlatformIntroductionText {
    font-size: 18px;
    margin: 0;
    padding: 0;
    color: #000000;
}
.BidPlatformHeaderText {
    font-size: 38px;
    font-weight: bold;
    margin-top:0px;
}

.BidPlatformDescriptionText {
    font-size: 20px;
    margin-top: -30px;
    color: #000000;
}

@media screen and (max-width: 768px) {
    .BidPlatformContainer {
        flex-direction: column; /* Stack the containers vertically */
        justify-content: center;
        align-items: center;
        padding: 20px; /* Lägg till lite padding runt hela containern */
    }

    .BidPlatformTextContainer {
        width: 90%; /* Gör texten mer responsiv */
        
    }
    .BidPlatformIntroductionText {
        font-size: 16px; /* Mindre fontstorlek för introduktionstext */
    }

    .BidPlatformHeaderText {
        font-size: 28px; /* Mindre fontstorlek för header */
        margin-bottom: 20px;
    }

    .BidPlatformDescriptionText {
        font-size: 16px; /* Mindre fontstorlek för beskrivning */
        margin-top: -20px;
    }

    .BidPlatformSubContainerLeft {
        justify-content: center;
        padding-right: 0;
        width: 100%; /* Justera bredden för mobil */
    }

    .BidPlatformSubContainerRight {
        justify-content: center;
        padding-left: 0;
        width: 80%; /* Justera bredden för mobil */
    }

    .BidPlatformVideo {
        
        padding: 40px;
    }
}


